import React, { Component } from 'react';
import * as Icon from 'react-feather';
import WaterBlaze from "../../../images/water7.jpg";
import Derekpic from "../../../images/water7.jpg";


const styles = {
    derekbackpic: {
        backgroundImage: `url(${Derekpic})`
    },
    backbannertrain: {
        backgroundImage: `url(${WaterBlaze})`
    }
};

class Aboutpageinternal extends Component {
    render() {

        return (
            <div>
                <section className="under-internalbanner graddown">
                    <div className="threeboxes">
                        <div className="container">
                            <div className="boxcard norise">
                                <div className="columns is-marginless is-centered">
                                    <div className="column is-6">
                                        <div className="biobox">
                                            <h3>Derek Herlihy</h3>
                                            <h4>Co-Founder &amp; Operations director</h4>
                                            <h5>Derek O’Herlihy is a Food Scientist, having graduated from CIT in 1991 and was awarded a Master of Science from the Open University in 2003</h5>
                                            <p>Derek’s career spans more than 30 years experience in the food manufacturing, spirits and the beverage industry. He has skills in operations management, quality management and the management of development projects</p>
                                            <p>Derek is a 20 year veteran of PepsiCo based in Cork, Ireland with an additional 10 years of providing training services to multi-national companies based in Ireland and the US</p>
                                        </div>
                                    </div>
                                    <div className="column is-6">
                                        <div className="biobox">
                                            <h3>Brian English</h3>
                                            <h4>Co-Founder &amp; Engineering director</h4>
                                            <h5>Brian English graduated from UCD in 1976 with a BE in Electrical Engineering and was awarded a Master of Industrial Engineering (MIE) at UCD in 1981</h5>
                                            <p>Brian’s career spans 40 years in industry, mainly in computers/electronics, including over 13 years experience as a General Manager. He has core skills in electronics and software development, operations, quality and engineering</p>
                                            <p>Brian has created and developed and continues to develop several software utilities for several companies in Ireland and the US over the past 20 years</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="timelinesection">
                    <div className="container">
                        <div className="columns is-marginless">
                            <div className="column is-10 is-offset-1 is-centered">

                                <div className="timeline">
                                    <header className="timeline-header">
                                        <span className="tag is-medium is-primary">Start</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker"></div>
                                        <div className="timeline-content">
                                            <p className="heading">2017</p>
                                            <p><span className="iconhandler"><Icon.ThumbsUp /></span></p>
                                            <p>Formation of Cool Harbour</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag is-primary">2018</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker is-image is-32x32">
                                        </div>
                                        <div className="timeline-content">
                                            <p className="heading">January 2018</p>
                                            <p><span className="iconhandler"><Icon.Package /></span></p>
                                            <p>Development of on-line Packaging Workflow Process</p>
                                        </div>
                                    </div>

                                    <header className="timeline-header">
                                        <span className="tag is-primary">2019</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker is-icon">
                                            <i className="fa fa-flag"></i>
                                        </div>
                                        <div className="timeline-content">
                                            <p className="heading">March 2019</p>
                                            <p><span className="iconhandler"><Icon.Clipboard /></span></p>
                                            <p>Development of LiveView on-line Assessment Tool and Data Management Portal
</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag is-primary">2020</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker is-icon">
                                            <i className="fa fa-flag"></i>
                                        </div>
                                        <div className="timeline-content">
                                            <p className="heading">2020</p>
                                            <p><span className="iconhandler"><Icon.Database /></span></p>
                                            <p>Continued Development of Data Management Portal and new development of LIMS for laboratories with Unique technology</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag is-primary">2021</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker is-icon">
                                            <i className="fa fa-flag"></i>
                                        </div>
                                        <div className="timeline-content">
                                            <p className="heading">2021</p>
                                            <p><span className="iconhandler"><Icon.Tool /></span></p>
                                            <p>Continued development of LiveView online assessment tool</p>
                                        </div>
                                    </div>
                                    <header className="timeline-header">
                                        <span className="tag is-primary">2022</span>
                                    </header>
                                    <div className="timeline-item">
                                        <div className="timeline-marker is-icon">
                                            <i className="fa fa-flag"></i>
                                        </div>
                                        <div className="timeline-content">
                                            <p className="heading">2022</p>
                                            <p><span className="iconhandler"><Icon.Package /></span></p>
                                            <p>Development of PackLIMS (laboratory information system for packaging labs)</p>
                                        </div>
                                    </div>
                                    <div className="timeline-header">
                                        <span className="tag is-medium is-primary">...</span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>


            </div>
        );
    }
}

export default Aboutpageinternal;