import React from "react"

import "../style/bulma.css"
import "../style/bulma-timeline.css"
import "../style/coolharbour-tech.css"
import "../style/coolharbour-gentext.css"
import "../style/coolharbour-tech-internal-livelab.css"
import HotWaterBack from "../images/water6.jpg";

import Layout from "../components/layout"
import Lesserinternalbanner from "../components/internalpages/generalinternal/lesserinternalbanner"
import Aboutpageinternal from "../components/internalpages/about/aboutpageinternal"

import Contactform from "../components/homepage/contactform"
import SEO from "../components/seo"
import Coolfooter from "../components/coolfooter"

const Aboutpage = () => (
  <Layout>
    <SEO title="Contact Cool Harbour" />
    <Lesserinternalbanner 
    backgrounder = {HotWaterBack}
    title="About Coolharbour"
    subtitle="40 years of industry experience"
    subcopy="Who are we?"
    />
        <Aboutpageinternal />
   <Contactform />
   <Coolfooter />
  </Layout>
)

export default Aboutpage